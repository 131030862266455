import { Component, LOCALE_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: { '[class]': 'componentClass' }
})
export class AppComponent {
  componentClass: string = 'root';
  constructor(@Inject(LOCALE_ID) protected localeId: string) {}
}
