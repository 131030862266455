/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/header/header.component.ngfactory";
import * as i2 from "./core/header/header.component";
import * as i3 from "@angular/router";
import * as i4 from "./shared/components/hero/hero.component.ngfactory";
import * as i5 from "./shared/components/hero/hero.component";
import * as i6 from "./core/services/data.service";
import * as i7 from "ngx-device-detector";
import * as i8 from "./core/footer/footer.component.ngfactory";
import * as i9 from "./core/footer/footer.component";
import * as i10 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [["data-headroom", ""], ["itemscope", ""], ["itemtype", "https://schema.org/WPHeader"], ["role", "banner"]], [[8, "className", 0]], null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [i3.Router], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-hero", [["itemprop", "primaryImageOfPage"], ["itemscope", ""]], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onresize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_HeroComponent_0, i4.RenderType_HeroComponent)), i0.ɵdid(3, 245760, null, 0, i5.HeroComponent, [i6.DataService, i7.DeviceDetectorService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "main", [["class", "wrap"], ["itemprop", "mainContentOfPage"], ["itemscope", ""], ["itemtype", "http://schema.org/Blog"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-footer", [["itemscope", ""], ["itemtype", "https://schema.org/WPFooter"], ["role", "contentinfo"]], [[8, "className", 0]], null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i0.ɵdid(8, 114688, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 3).componentClass; _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 8).componentClass; _ck(_v, 7, 0, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], [[8, "className", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i10.AppComponent, [i0.LOCALE_ID], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i0.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
