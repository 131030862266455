<section class="section section__featured md__columns-2 margin__bottom" itemscope itemtype="https://schema.org/Article">
  <div class="content__inner-left module__text has__assets">
    <div class="content__wrapper">
      <div class="content__wrapper-inner">

        <h2 class="entry__title">RVTS har et viktig samfunnsoppdrag i å forebygge traumelidelser og selvmord</h2>
          <div [innerHTML]="data[0].content.rendered | safe:'html'" class="content is-ingress entry__content" itemprop="text"
            *ngIf="data[0].content.rendered; else default"></div>

          <ng-template #default>
            <div class="content is-ingress" itemprop="text">
              <p>Vår oppgave er å bidra til relevant kompetanseutvikling for de som arbeider
                med forebygging av dette. Det gjør vi sammen med fagfolk og ledere, både innenfor og på tvers av
                organisasjoner.</p>
            </div>

            <a class="btn btn-outline-primary" [routerLink]="['/om-rvts']">Les mer om vår oppgave</a>

          </ng-template>

      </div>
    </div>

  </div>

  <div class="content__inner-right module__assets">
    <div class="entry-content-asset">
      <iframe src="//player.vimeo.com/video/55359907?title=0&amp;portrait=0" width="500" height="281" frameborder="0"
        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </div>
</section>

<app-collaborators></app-collaborators>


<section class="section section__map md__columns-2 section__flip" itemscope itemtype="https://schema.org/Article">
  <div class="content__inner-left module__text has__assets">
    <div class="content__wrapper">
      <div class="content__wrapper-inner">
        <h2 class="entry__title">De regionale ressurssentrene</h2>

        <div class="entry__content">
          <p class="lead">
            De regionale ressurssentrene om vold, traumatisk stress og selvmordsforebygging (RVTS) er en ressurs for
            deg som i ditt arbeid møter mennesker berørt av vold og seksuelle overgrep, traumatisk stress, migrasjon
            eller selvmordsproblematikk.</p>
        </div>

        <nav class="navbar__nav">
          <ul class="nav nav__primary nav__primary-list">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/region/nord']" [routerLinkActive]="['active']">Region Nord</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/region/midt']" [routerLinkActive]="['active']">Region Midt</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/region/ost']" [routerLinkActive]="['active']">Region Øst</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/region/vest']" [routerLinkActive]="['active']">Region Vest</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/region/sor']" [routerLinkActive]="['active']">Region Sør</a>
            </li>
          </ul>
        </nav>

      </div>
    </div>

  </div>

  <div class="content__inner-right module__background color__gray" *ngIf="map">
    <agm-map [latitude]="lat" [longitude]="lng" [draggable]=false [scrollwheel]=null [gestureHandling]="'coopeative'"
      [styles]="styles" [streetViewControl]="false" [zoom]="4">
      <agm-marker [latitude]="item['acf']['contact']['map']['lat']" [longitude]="item['acf']['contact']['map']['lng']"
        *ngFor="let item of map"></agm-marker>
    </agm-map>
  </div>
</section>
