/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./page.component";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-meta/core";
import * as i5 from "../../../core/services/data.service";
import * as i6 from "../../../core/services/api.service";
var styles_PageComponent = [];
var RenderType_PageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
function View_PageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "module__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_1 = "module__text"; var currVal_2 = (_v.parent.context.$implicit.image ? "has__assets" : "container container__md"); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.wysivyg; _ck(_v, 0, 0, currVal_0); }); }
function View_PageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "figure", [["class", "module__background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image.sizes.content, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", (_v.parent.context.$implicit.image.description ? _v.parent.context.$implicit.image.description : "Bilde"), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "section md__columns-auto margin__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.wysivyg; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.image; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blocks; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page", [], [[8, "className", 0]], null, null, View_PageComponent_0, RenderType_PageComponent)), i0.ɵdid(1, 245760, null, 0, i2.PageComponent, [i3.ActivatedRoute, i3.Router, i4.MetaService, i5.DataService, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var PageComponentNgFactory = i0.ɵccf("app-page", i2.PageComponent, View_PageComponent_Host_0, {}, {}, []);
export { PageComponentNgFactory as PageComponentNgFactory };
