import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ApiService } from './../../../core/services/api.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  host: { '[class]': 'componentClass' }
})
export class CollaboratorsComponent implements OnInit, OnDestroy {
  componentClass: string = 'section is-collaborators';

  subscription: Subscription;
  data: object;

  constructor(
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.subscription = this._apiService.getData('/collaborators', {
      // params: {

      // }
    })
    .subscribe((res) => {
      if (res) {
        this.data = res;
      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    // this.subscription.unsubscribe();
  }
}
