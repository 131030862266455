/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "container container__lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 18, "div", [["class", "content__info-disclaimer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "legal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h6", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\u00A9 ", " ", " - ", ""])), i0.ɵppd(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 10, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["Vi respekterer og beskytter personvernet og personopplysningene dine. Les om bruk av informasjonskapsler (cookies), og om hvordan ", " samler inn og bruker "])), (_l()(), i0.ɵeld(9, 0, null, null, 7, "a", [["class", "btn btn__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(11, 1), i0.ɵdid(12, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵpad(15, 1), (_l()(), i0.ɵted(-1, null, ["personopplysninger"])), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(18, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "a", [["class", "btn btn__link"], ["href", "https://uustatus.no/nb/erklaringer/publisert/098941e7-18c4-4786-8a10-9223355101a3"], ["rel", "noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Tilgjengelighetserkl\u00E6ring"])), (_l()(), i0.ɵeld(21, 0, null, null, 0, "div", [["class", "content__info-meta meta"]], null, null, null, null, null))], function (_ck, _v) { var currVal_6 = _ck(_v, 11, 0, "/personvern"); _ck(_v, 10, 0, currVal_6); var currVal_7 = _ck(_v, 15, 0, "active"); _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.today, "yyyy")); var currVal_1 = _co.company; var currVal_2 = _co.companyExtended; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.company; _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 10).target; var currVal_5 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], [[8, "className", 0]], null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var FooterComponentNgFactory = i0.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
