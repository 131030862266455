import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageComponent } from '../shared/components/page/page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'ressurser',
    loadChildren: './resources/resources.module#ResourcesModule'
  },
  {
    path: 'region',
    loadChildren: './region/region.module#RegionModule'
  },
  {
    path: ':slug',
    component: PageComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PageRoutingModule { }
