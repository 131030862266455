import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MetaService } from '@ngx-meta/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../../core/services/api.service';
import { DataService } from './../../../core/services/data.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  host: { '[class]': 'componentClass' }
})
export class PageComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;

  componentClass: string;
  slug: string;

  data: any;
  blocks: any;
  imageService: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly _meta: MetaService,
    // private _http: HttpClient,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') !== 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData('/pages', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res) {
              this.componentClass = 'page page__' +res[0]['slug'];
              this._dataService.setData(res)
              this.data = res;

              if ( res[0]['acf'] && res[0]['acf']['blocks'] ) {
                this.blocks = res[0]['acf']['blocks'];
              }

              this._meta.setTitle(res[0].title.rendered);

              if ( res[0].better_featured_image) {
                this.imageService = res[0].better_featured_image.media_details.sizes;
              }

              if ( this.imageService ) {
                this._meta.setTag('og:image', environment.domain +this.imageService.featured_desktop.source_url);
                this._meta.setTag('og:image:width',  this.imageService.featured_desktop.width);
                this._meta.setTag('og:image:height', this.imageService.featured_desktop.height);
              }

              if ( res[0].excerpt ) {
                this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
              }

              this._meta.setTag('og:type', 'website');
            }
            else {
              this._router.navigate(['./404']);
              return 404;
            }

          })
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
  }
}
