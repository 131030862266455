/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-lazyload-image";
import * as i2 from "@angular/common";
import * as i3 from "./hero.component";
import * as i4 from "../../../core/services/data.service";
import * as i5 from "ngx-device-detector";
var styles_HeroComponent = [];
var RenderType_HeroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "hero__background"]], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featuredImage; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeroComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "hero__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "color__default"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero.title; _ck(_v, 2, 0, currVal_0); }); }
function View_HeroComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "hero__title-sub"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "color__primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero.subtitle; _ck(_v, 2, 0, currVal_0); }); }
function View_HeroComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "header", [["class", "hero__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "hero__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_4)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_5)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hero.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hero.subtitle; _ck(_v, 5, 0, currVal_1); }, null); }
function View_HeroComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "hero__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayTitle; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HeroComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featuredImage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.hero; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-hero", [], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onresize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeroComponent_0, RenderType_HeroComponent)), i0.ɵdid(1, 245760, null, 0, i3.HeroComponent, [i4.DataService, i5.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var HeroComponentNgFactory = i0.ɵccf("app-hero", i3.HeroComponent, View_HeroComponent_Host_0, {}, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };
