import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataService {
    constructor() {
        this._apiData = new BehaviorSubject(null);
        this._apiData$ = this._apiData.asObservable();
    }
    setData(data) {
        this._apiData.next(data);
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
