<div class="container container__lg">
  <div class="content__info-disclaimer">
    <div class="legal">
      <h6 class="title">&copy; {{today | date:'yyyy'}} {{company}} - {{companyExtended}}</h6>
      <p>Vi respekterer og beskytter personvernet og personopplysningene dine. Les om bruk av informasjonskapsler
        (cookies), og om hvordan {{company}} samler inn og bruker <a class="btn btn__link"
          [routerLink]="['/personvern']" [routerLinkActive]="['active']">personopplysninger</a>.</p>

      <p><a class="btn btn__link" href="https://uustatus.no/nb/erklaringer/publisert/098941e7-18c4-4786-8a10-9223355101a3" target="_blank" rel="noreferrer">Tilgjengelighetserklæring</a></p>

    </div>
  </div>

  <div class="content__info-meta meta"></div>
</div>
