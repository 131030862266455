import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService } from './../../../core/services/data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isDevMode } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  host: { '[class]': 'componentClass' }
})
export class HeroComponent implements OnInit, OnDestroy {
  componentClass: string;
  slug: string;
  subscription: Subscription;

  heroDetail: string;
  hero: any;

  displayTitle: boolean = true;
  defaultHero: boolean;
  type: string;

  imageService: any;
  featuredImage: string;

  OGimage: string = null;
  OGwidth: string = null;
  OGheight: string = null;

  deviceInfo = null;
  mobileDevice = null;
  tabletDevice = null;
  desktopDevice = null;


  constructor(
    private _dataService: DataService,
    private _deviceService: DeviceDetectorService,
  ) {}
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.deviceFunction();
  }

  ngOnInit() {
    this.deviceFunction();
    this.subscription = this._dataService._apiData$
    .subscribe((res) => {

      this.imageService = '';
      this.featuredImage = '';
      this.type = '';
      this.hero = [];

      if (res) {
        this.componentClass = 'hero hero__home';

        if (res[0].type) {
          this.type = res[0].type;
        }

        if (this.type === 'post') {
          this.displayTitle = false;
        }

        if ( res[0].better_featured_image) {
          this.imageService = res[0].better_featured_image.media_details.sizes;
          if ( this.imageService.featured_desktop ) {
            this.featuredImage = this.imageService.featured_desktop.source_url;
          } else {
            this.featuredImage = this.imageService.content.source_url;
          }
          if ( this.mobileDevice ) {
            if ( this.imageService.featured_mobile ) {
              this.featuredImage = '' +this.imageService.featured_mobile.source_url;
            }
          }
        }

        this.hero['title'] = res[0].title.rendered;
        this.hero['subtitle'] = false;
        if ( res[0].acf.sub_title ) {
          this.hero['subtitle'] = res[0].acf.sub_title;
        }

        if(isDevMode()) {
          console.log('hero, ' +JSON.stringify(this.hero));
          console.log('images, ' +JSON.stringify(this.imageService));
          console.log('image, ' +this.featuredImage);
          console.log('subTitle,' +this.hero['subTitle']);
          console.log('type, ' +this.type);
          console.log('title, ' +this.displayTitle);
          console.log('device, ' +JSON.stringify(this.deviceInfo));
          console.log('mobile, ' +this.mobileDevice);
          console.log('tablet, ' +this.tabletDevice);
          console.log('desktop, ' +this.desktopDevice);
          console.log('defaultHero, ' + this.defaultHero);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.imageService = '';
    this.featuredImage = '';
    this.hero = [];
    this.defaultHero = false;
    this.type = '';
  }


  deviceFunction(): void {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    this.mobileDevice = this._deviceService.isMobile();
    this.tabletDevice = this._deviceService.isTablet();
    this.desktopDevice = this._deviceService.isDesktop();
  }
}
