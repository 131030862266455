import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { PageRoutingModule } from './page-routing.module';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    PageRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAk_aPGO341GF6yNrxrjB-yk0XywZB0_L4'
    }),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    SharedModule
  ],
  declarations: [
    HomeComponent,
    AboutComponent,
  ]
})
export class PageModule { }
