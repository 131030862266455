import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

import { HeroComponent } from './components/hero/hero.component';
import { PageComponent } from './components/page/page.component';
import { CollaboratorsComponent } from './components/collaborators/collaborators.component';

import { SafePipe } from './pipes/safe.pipe';
import { CategoryPipe } from './pipes/category.pipe';
import { OptionsPipe } from './pipes/options.pipe';
import { SearchPipe } from './pipes/search.pipe';


@NgModule({
  declarations: [
    HeroComponent,
    PageComponent,
    CollaboratorsComponent,
    SafePipe,
    CategoryPipe,
    OptionsPipe,
    SearchPipe,
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    })
  ],
  exports: [
    HeroComponent,
    PageComponent,
    CollaboratorsComponent,
    SafePipe,
    CategoryPipe,
    OptionsPipe,
    SearchPipe,
  ]
})
export class SharedModule { }
