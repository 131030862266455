import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { Subscription } from 'rxjs';

import { ApiService } from './../../core/services/api.service';
import { DataService } from './../../core/services/data.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  host: { '[class]': 'componentClass' }
})
export class HomeComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;

  componentClass: string;
  slug: string = 'forside';

  data: any;
  imageService: any;
  mapData: Subscription;
  map: any;

  lat: number = parseFloat('65.28000000000002');
  lng: number = parseFloat('13.435781249999978');

  styles: any[] = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._apiService.getData('/pages', {
      params: {
        slug: this.slug
      }
    })
    .subscribe((res) => {
      if (res) {
        this._dataService.setData(res)
        this.componentClass = 'page page__' +res[0]['slug'];
        this.data = res;

        this._meta.setTitle(res[0].title.rendered);

        if ( res[0].better_featured_image) {
          this.imageService = res[0].better_featured_image.media_details.sizes;
        }

        if ( this.imageService ) {
          this._meta.setTag('og:image', environment.domain +this.imageService.featured_desktop.source_url);
          this._meta.setTag('og:image:width',  this.imageService.featured_desktop.width);
          this._meta.setTag('og:image:height', this.imageService.featured_desktop.height);
        }

        if ( res[0].excerpt ) {
          this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
        }

        this._meta.setTag('og:type', 'website');
      }
      else {
        this._router.navigate(['./404']);
        return 404;
      }
    })

    this.mapData = this._apiService.getData('/pages', {
      params: {
        include: ['38,40,41,42,44']
      }
    })
    .subscribe((res) => {
      // console.log('mapData, ' +JSON.stringify(res))
      this.map = res;
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this.mapData.unsubscribe();
  }
}
