/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./collaborators.component";
import * as i3 from "../../../core/services/api.service";
var styles_CollaboratorsComponent = [];
var RenderType_CollaboratorsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollaboratorsComponent, data: {} });
export { RenderType_CollaboratorsComponent as RenderType_CollaboratorsComponent };
function View_CollaboratorsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.acf.logo.sizes.thumbnail, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.acf.logo.description, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CollaboratorsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title.rendered; _ck(_v, 0, 0, currVal_0); }); }
function View_CollaboratorsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "collaborator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["rel", "noopener"], ["target", "_black"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollaboratorsComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["default", 2]], null, 0, null, View_CollaboratorsComponent_4))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.acf.logo; var currVal_2 = i0.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.acf.url, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_CollaboratorsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "container container__lg"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollaboratorsComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CollaboratorsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollaboratorsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CollaboratorsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-collaborators", [], [[8, "className", 0]], null, null, View_CollaboratorsComponent_0, RenderType_CollaboratorsComponent)), i0.ɵdid(1, 245760, null, 0, i2.CollaboratorsComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var CollaboratorsComponentNgFactory = i0.ɵccf("app-collaborators", i2.CollaboratorsComponent, View_CollaboratorsComponent_Host_0, {}, {}, []);
export { CollaboratorsComponentNgFactory as CollaboratorsComponentNgFactory };
