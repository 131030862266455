import { LOCALE_ID, NgModule } from '@angular/core';

import { registerLocaleData, DatePipe } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
registerLocaleData(localeNb, 'no');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    AppRoutingModule,
    AppModule,
  ],
  providers: [
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'no'
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppBrowserModule { }
