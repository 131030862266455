import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  host: { '[class]': 'componentClass' }
})
export class HeaderComponent implements OnInit {
  componentClass = '';
  isOpen: boolean;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
      this.isOpen = false;
    });
  }

  toggleNav() {
    this.isOpen = !this.isOpen;
  }

}
