import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiService {
    constructor(_http) {
        this._http = _http;
        this.root = environment.domain + environment.base + environment.wp;
    }
    getData(data, options) {
        return this._http.get(this.getUrl(data), options ? options : false);
    }
    postData(data, options) {
        return this._http.post(this.getUrl(data), options ? options : false);
    }
    getUrl(url) {
        return this.root + url;
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
