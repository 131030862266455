<div class="container container__lg" *ngIf="data">
  <div class="collaborator" *ngFor="let c of data">
    <a href="{{c.acf.url}}" target="_black" rel="noopener">
      <img *ngIf="c.acf.logo; else default" src="{{c.acf.logo.sizes.thumbnail}}" alt="{{c.acf.logo.description}}" />

      <ng-template #default>
        {{c.title.rendered}}
      </ng-template>

    </a>
  </div>
</div>